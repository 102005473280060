@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700;800&family=Fira+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,900&family=Raleway:wght@400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    color: inherit !important;
    text-decoration: none !important;
}

a:hover {
    color:inherit !important;
    cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}