.mint_cover_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 50px;
    padding-top: 10px;
    font-family: 'Cairo', sans-serif;

}

.mint_cover_bear_container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 50%;
    text-align: center;
}

.mint_cover_bear_container img {
    width: 100%;
    margin-bottom: -1px;
    height: auto;
    max-width: 1000px;

}

.mint_cover_content_container {
    width: 50%;
    padding-top: 70px;
    padding-bottom: 50px;
}

.m_bear_heading_container {
    width: 100%;
    margin-bottom: 20px;
    padding: 0 10px;
    text-align: center;
}

.m_bear_heading_container img {
    width: 100%;
    max-width: 2000px;
    height: auto;
}

.to_open_mintbox_button {
    background-color: rgba(246, 255, 0, 0.678);
}

.to_open_mintbox_button {
    font-family: 'Cairo', sans-serif;
    padding: 16px 28px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-left: 30px;
    border-radius: 8px;
    display: block;
    margin: 0 auto;
    border: none;
    border-style: solid;
    background-color: #5100a2;
    color: white;
    transition: background 200ms, color 300ms, border-color 300ms;
    -webkit-transition: background 200ms, color 300ms, border-color 300ms;
}

a.to_open_mintbox_button {
    color: white!important;
    display: table;
}

.to_open_mintbox_button:hover {
    background-color: rgba(81, 0, 162, 0.9);
}


@media screen and (max-width: 768px) {

    .mint_cover_container {
        display: block;
        padding: 102px 20px;
        padding-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        max-width: 470px;
    }

    .mint_cover_bear_container {
        display: block;
        width: 100%;
    }

    .mint_cover_content_container {
        width: 100%;
        padding-top: 10px;
    }

    .m_bear_heading_container {
        width: 100%;
        margin-bottom: 20px;
        padding: 0px;
    }

    .m_bear_heading_container img {
        width: 100%;
    }

    .to_open_mintbox_button {
        font-family: 'Cairo', sans-serif;
        padding: 14px 22px;
        font-size: 14px;
        border-radius: 8px;

    }



}