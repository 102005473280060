.join_project_container_bg {
    background-image: url('../../assets/images/bg22.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -3;
}

.join_project_container {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(43, 0, 86, 0.5));
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 170px 50px 0px 50px;
    /* padding: 0 85px; */
}

.join_project_content_container {
    width: 45%;
    text-align: center;
    margin-left: 75px;
    margin-right: 75px;
    padding-bottom: 75px;
}

.join_project_image_container {
    width: 55%;
    align-self: stretch;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.join_project_image_container img {
    width: 100%;
    vertical-align: bottom;
    max-width: 2000px;
    height: auto;
}

.join_project_content_container h1 {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 800;
    line-height: 60px;
    color: #ffffff;
    font-size: 50px;
    margin-bottom: 30px;
}

.join_project_content_container p {
    margin-top: 20px;
    color: #ffffff;
    line-height: 1.7;
    font-size: 16px;
    font-family: 'Cairo', sans-serif;

}

.join_project_container button {
    letter-spacing: 0.8px;
    font-weight: bold;
    font-size: 13px;
    color: #ffffff;
    background: transparent;
    border: 2px solid white;
    padding: 15px 33px;
    cursor: pointer;
    margin-top: 30px;
    text-transform: uppercase;
    font-family: 'Cairo', sans-serif;
}


.youtube_{
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}
.youtube_ iframe{
    width: 100% ;
    max-width: 560px;
}


@media screen and (max-width: 1334px) {

    .join_project_image_container {
        align-items: center;
    }
}



@media screen and (max-width: 768px) {

    .join_project_container_bg {
        background-attachment: scroll;
    }

    .join_project_container {
        display: block;
        padding: 150px 30px 0px 30px;
        /* padding: 0 85px; */
    }

    .join_project_content_container {
        width: 100%;
        max-width: 470px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 45px;
    }

    .join_project_image_container {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 470px;
        width: 100%;
    }

    .join_project_content_container h1 {
        line-height: 48px;
        font-size: 40px;
    }
}