.roadmap_container_bg {
    background-image: url(../../assets/images/bg3.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}

.roadmap_container {
    max-width: 1340px;
    width: 100%;
    margin: 0 auto;
    padding-top: 90px;
    padding-bottom: 230px;
    padding-left: 0px;
}

.roadmap_content h1 {
    font-size: 60px;
    line-height: 72px;
    font-weight: 900;
    font-style: italic;
    color: #5100a2;
    font-family: 'Fira Sans', sans-serif;
}

.roadmap_content p {
    font-size: 20px;
    line-height: 34px;
    font-weight: 400;
    font-style: italic;
    font-family: Georgia, Times, serif;
}

.roadmap_sections {
    margin-top: 45px;
}

.r_row {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    font-family: 'Cairo', sans-serif;
    padding-left: 20px;
    padding-bottom: 10px;
}

.r_row .r_num {
    width: 230px;
    font-size: 60px;
    line-height: 1;
    font-weight: 800;
    color: #5100a2;
    position: relative;

}

.r_bullet {
    display: inline-block;
    width: 11px;
    height: 11px;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 10px;
    margin-left: 28px;
    box-sizing: 50%;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.r_bullet .r_line {
    position: absolute;
    color: #5100a2;
    top: 35px;
    bottom: -30px;
    width: 20px;
    margin-left: 4px;
    border-left: 1px solid #5100a2;
}

.r_row:last-child .r_line {
    display: none;

}

.r_row .r_content {
    width: 100%;
    padding-top: 12px;
    padding-right: 20px;
}

.r_row .r_content h1 {
    font-size: 24px;
    line-height: 41px;
    font-weight: 900;
    color: black;
}

.r_row .r_content p {
    font-weight: 600;
    font-size: 21px;
    line-height: 36px;
    margin: 0;
    color: black;
}

.r_row .r_image_wrapper {
    width: 460px;
    max-width: 460px;
    margin-top: 20px;
    height: 100%;
    align-self: center;
}

.r_row .r_image_wrapper img {
    width: 90%;
    height: auto;
}

/* .r_row .r_image_wrapper {
    width: 100%;
    max-width: 280px;
    margin-top: 20px;
    height: 100%;
}

.r_row .r_image_wrapper img {
    width: 100%;
    height: auto;
} */

@media screen and (max-width: 1210px) {
    .roadmap_container {
        padding-left: 50px;

    }

    .r_row .r_content {
        width: 100%;
        padding-top: 12px;
        padding-right: 10px;
    }


}

@media screen and (max-width: 768px) {

    .roadmap_container {
        max-width: none;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 180px;


    }

    .roadmap_content h1 {
        font-size: 40px;
        line-height: 48px;
    }


    .roadmap_sections {
        margin-top: 45px;
    }

    .r_row {
        padding-left: 0px;
        padding-bottom: 10px;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    .r_row .r_num {
        width: 20%;
        max-width: 200px;
        flex-basis: 20%;
        padding-right: 0px;
        font-size: 60px;

    }

    .r_bullet {
        display: none;
    }

    .r_row .r_content {
        width: 100%;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 30px;
        flex-basis: 80%;
    }

    .r_row .r_image_wrapper {
        /* width: 100%; */
        flex-basis: 100%;
        width: 100%;
        max-width: 100%;
        margin-top: 20px;
        margin-bottom: 30px;
        height: 100%;
        align-self: center;
        text-align: center;
    }

    .r_row .r_image_wrapper img {
        width: 100%;
        max-width: 400px;

    }
}