.team_container {
    text-align: center;
    padding: 55px 50px;
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    padding-top: 90px;

}

.team_member_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 60px;
}

.team_member {
    padding: 0 10px;
}

.team_image {
    text-align: center;
    width: 100%;
}

.team_image img {
    max-width: 160px;
    width: 100%;

}

.team_member h3 {
    font-family: 'Fira Sans', sans-serif;
    font-size: 23px;
    font-weight: normal;
    line-height: 28px;
    margin-top: 30px;
}

.team_member p {
    font-family: 'Georgia', sans-serif;
    font-size: 16px;
    line-height: 27px;
    color: #747474;
    font-style: italic;
    ;
}

.team_heading h1 {
    font-size: 60px;
    line-height: 72px;
    color: #5100a2;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 900;
    letter-spacing: 1px !important;
}

@media screen and (max-width: 768px) {
    .team_container {
        text-align: center;
        padding: 55px 0;
        max-width: 470px;
    }

    .team_member_wrapper {
        display: block;
        margin-top: 45px;
    }

    .team_member {
        padding: 5px 0px;
    }

    .team_image img {
        max-width: 180px;
        width: 100%;
    }

    .team_member h3 {
        margin-bottom: 2px;
    }

    .team_heading h1 {
        font-size: 40px;
        line-height: 48px;
    }
}