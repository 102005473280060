.maestros_container {
  background: url('../../assets/images/bg1.jpg');
  background-size: cover;
  background-position: center;
  padding-top: 150px;
  text-align: center;
  padding-right: 55px;
  padding-left: 55px;
  padding-bottom: 35px;
}

.maestros_content {
  padding-bottom: 80px;
}

.maestros_content h1 {
  font-size: 64px;
  font-weight: 800;
  line-height: 77px;
  color: white;
  font-family: 'Fira Sans', sans-serif;
  margin-bottom: 30px;
}

.maestros_content p {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  color: white;
  font-family: 'Cairo', sans-serif;
}


.swiper {
  width: 100%;
  padding-bottom: 50px !important;
  /* height: 500px; */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  max-width: 500px;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  width: 27px !important;
  height: 5px !important;
  margin-left: 5px !important;
  text-align: center;
  line-height: 0px !important;
  font-size: 12px;
  color: #000;
  opacity: 1;
  border-radius: 3px !important;
  background: #ffffff !important;
}
.swiper-button-next, .swiper-button-prev {
  background-color: rgba(0, 0, 0, 0.5) !important;
  padding: 25px !important;
  border-radius: 50% !important;
}
.swiper-button-next:after, .swiper-button-prev:after{
  font-size: 20px !important;
  color: #fff !important;
  font-weight: bold !important;
  
}


.swiper-pagination {
  bottom: 0 !important;
}
.swiper-pagination-bullet{
  background-color: #fff !important;
  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  background: #8a8a8a !important;
}

@media screen and (max-width: 768px) {
  .maestros_container {
    background-position: center;
    padding-top: 140px;
    text-align: center;
    padding-right: 30px;
    padding-left: 30px;
  }

  .maestros_content h1 {
    font-size: 40px;
    line-height: 48px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }

}