.rarity_container {
    max-width: 1440px;
    padding: 90px 20px;
    width: 100%;
    margin: 0 auto;
}

.rarity_images {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-top: 80px;
}

.r_image_wrapper {
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

}

.rarity_text {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    font-family: 'Cairo', sans-serif;

}

.r_image_wrapper img {
    width: 100%;
}

.sub_images {
    width: 50%;
}

.sub_images {
    padding: 0 50px;
    display: flex;
}

.rarity_text {
    padding-left: 20px;
}

.rarity_text h1 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    color: black;
}

.rarity_text p {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: black;
}

.rarity_heading {
    text-align: center;
    margin-bottom: 60px;
}

.rarity_heading h1 {
    font-size: 60px;
    line-height: 72px;
    color: #5100a2;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 900;
    margin-bottom: 30px;
    letter-spacing: 1px;
}

.rarity_heading p {
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    color: black;
    font-weight: 600;
    font-family: 'Cairo', sans-serif;
}

@media screen and (max-width: 1281px) {

    .sub_images {
        padding: 0 12px;

    }

    /* .rarity_text h1 {
        font-size: 22px;
        line-height: 28px;
        font-weight: 800;
        color: black;
    }

    .rarity_text p {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: black;
    } */
}

@media screen and (max-width: 1108px) {

    .rarity_text h1 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 800;
        color: black;
    }

    .rarity_text p {
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
        color: black;
    }

}
@media screen and (max-width: 898px) {
    .rarity_images {
        margin-top: 40px;
    }

    .rarity_text h1 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 800;
        color: black;
    }

    .rarity_text p {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        color: black;
    }

}

@media screen and (max-width: 768px) {

    .rarity_container {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        max-width: 470px;
        padding: 90px 30px;
    }

    .rarity_images {
        display: block;
    }

    .r_image_wrapper {
        width: 100%;

        display: block;

    }

    .rarity_text {
        width: 100%;
    }

    .r_image_wrapper img {
        width: 100%;
    }

    .sub_images {
        width: 100%;
    }

    .sub_images {
        padding: 0 0px;
        display: block;
        margin-top: 60px;
    }

    .rarity_heading {
        text-align: center;
        margin-bottom: 40px;
    }

    .rarity_heading h1 {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 20px;

    }


    .rarity_text {
        padding-left: 0px;
        margin-top: 10px;
        text-align: center;
        display: block;
    }

    .rarity_text h1 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 800;
    }

    .rarity_text p {
        font-size: 16px;
        line-height: 27px;
    }
}