.m_navbar_container {
    display: none;
}

.Navbar_container {
    width: 100%;
}

.first_nav_section {
    background-color: #5100a2;
    padding: 10px 0;
    border-bottom: 1px solid white;
    line-height: 1.6;
    padding-left: 85px;
    padding-right: 85px;
}

.nav_icons_container {
    margin-right: auto;
    text-align: right;
}

.first_nav_section span {
    color: white;
    font-size: 16px;
    line-height: 1;
    padding: 0 10px;
    cursor: pointer;
    transition: background 300ms, color 300ms, border-color 300ms;
    -webkit-transition: background 300ms, color 300ms, border-color 300ms;
}

.first_nav_section span:hover {
    color: #1e73be;
}

.second_nav_section {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 15px 85px;
    background-color: black;
    position: sticky;
    top: 0;
}

.logo_container {
    width: 200px;
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo_container img {
    height: auto;
    width: 100%;
}

.menu_container {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu_container span {
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 20px;
    display: inline-block;
}

.menu_container span::after {
    display: block;
    content: '';
    width: calc(100% + 12px);
    border-bottom: solid 3px #5100a2;
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
    margin-left: -6px;
}

.menu_container span:hover:after {
    transform: scaleX(1);
}

.active_menu::after {
    transform: scaleX(1) !important;

}

.active_menu a {
    transition: background 300ms, color 300ms, border-color 300ms;
    -webkit-transition: background 300ms, color 300ms, border-color 300ms;
    /* color: #5100a2 !important; */

}

.nav_button_container {
    line-height: 1.7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav_button_container button {
    font-family: 'Cairo', sans-serif;
    padding: 8px 15px;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-left: 30px;
    border-radius: 3px;
    border-width: 2px;
    border-style: solid;
    background-color: #5100a2;
    color: white;
    transition: background 200ms, color 300ms, border-color 300ms;
    -webkit-transition: background 200ms, color 300ms, border-color 300ms;
    border-color: #5100a2;
    text-transform: uppercase;
}

.nav_button_container button:hover {
    background-color: rgba(81, 0, 162, 0.9);
}

.nav_button_container button.whitelist {
    padding: 5.5px 40px;
    font-size: 14px;
    margin-left: 30px;
    background-color: #fff;
    color: #000;
    border-color: #fff;
}

.nav_button_container button.whitelist:hover {
    background-color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0.9);
}

.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
}

.add_padding {
    margin-bottom: 106.1px;
}


.anchor_container {
    position: fixed;
    right: 30px;
    z-index: 9222;
    bottom: 30px;
}

.back-to-top-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    width: 45px;
    height: 45px;
    text-align: center;
    padding: 12px;
    cursor: pointer;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    transition: opacity 300ms;
    -moz-transition: opacity 300ms;
    -o-transition: opacity 300ms;
    -webkit-transition: opacity 300ms;
    background-color: #5100a2;
    color: #ffffff !important;
}

.back-to-top-button:hover {
    color: #ffffff !important;
}

.display_none {
    display: none !important;
}




@media screen and (max-width: 1297px) {
    .second_nav_section {
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 15px 15px;
        background-color: black;
        top: 0;
    }

    .first_nav_section {
        padding: 15px;
    }

    .first_nav_section span {
        padding-right: 0px;
        padding-left: 20px;
    }
}

@media screen and (max-width: 1158px) {

    .nav_button_container button.whitelist {
        padding: 5.7px 25px;
        font-size: 13px;

    }

    .logo_container {
        width: 160px;
        min-width: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo_container img {
        height: auto;
        width: 100%;
    }
}

@media screen and (max-width: 1080px) {

    .menu_container span {
        padding: 10px;
    }
}

@media screen and (max-width: 914px) {

    .menu_container span {
        padding: 10px;
    }

    .menu_container {
        display: none;
    }
}

@media screen and (max-width: 768px) {

    .menu_container {
        display: none;
    }

    .nav_button_container {
        display: none;
    }

    .logo_container {
        margin: 0 auto;
    }
}

@media screen and (max-width: 768px) {

    .Navbar_container {
        display: none;
    }

    .m_navbar_container {
        display: block;
        background-color: black;
        text-align: center;
        padding: 15px;
        position: fixed;
        width: 100%;
        z-index: 3232;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .m_navbar_container img {
        width: 180px;
    }

    .m_navbar_container button {
        font-family: 'Cairo', sans-serif;
        padding: 5.5px 18px;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-left: 30px;
        border-radius: 3px;
        border-width: 2px;
        border-style: solid;
        background-color: #fff;
        color: #000;
        transition: background 200ms, color 300ms, border-color 300ms;
        -webkit-transition: background 200ms, color 300ms, border-color 300ms;
        border-color: #fff;
    }
}