.artist_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding-top: 80px;
    padding-left: 50px;
    padding-right: 50px;
}

.artist_content_container {
    width: 50%;
    padding-right: 0px;
    padding-left: 70px;
    padding-bottom: 50px;

}

.artist_content_container .youtube_ {
    text-align: left;
}


.artist_image_container {
    align-self: stretch;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 50%;
    text-align: center;
    align-self: stretch;

}

.artist_image_container img {
    width: 100%;
    height: auto;
    align-self: flex-end;
    max-width: 1000px;

}

.artist_content_container h1 {
    font-size: 50px;
    line-height: 60px;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 800;
    color: #5100a2;
    margin-bottom: 30px;
    letter-spacing: 1px;
}

.artist_content_container p {
    font-size: 20px;
    text-transform: none;
    color: #000000;
    font-family: 'Cairo', sans-serif;
    line-height: 34px;
}

@media screen and (max-width: 768px) {
    .artist_container {
        display: block;
        padding: 120px 30px;
        padding-bottom: 90px;
        margin-left: auto;
        margin-right: auto;
        max-width: 470px;
    }

    .artist_content_container {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 80px;
        padding: 0;
    }

    .artist_image_container {
        width: 100%;
        margin-top: 0px;
        padding-right: 0;
        padding-left: 0;
    }

    .artist_image_container img {
        width: 100%;
    }

    .artist_content_container {
        padding-left: 0px;
    }

    .artist_content_container h1 {
        font-size: 40px;
        line-height: 48px;
    }

}