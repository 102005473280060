.modal_main_container {
    z-index: 1212222 !important;
    margin: 0 !important;
}

.modal-content {
    position: relative !important;
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.70) !important;
    overflow: hidden;
}

.first_slider {
    width: 452px;
    position: absolute;
    top: 92px;
    left: 50%;
    transform: translateX(-226px);
    height: 80% !important;
    z-index: 12122222 !important;

}

.first_slider .slide_container {
    background: #000;
    padding: 5px;
    margin: 20px;
    -webkit-box-shadow: 0 0 15px hsl(0deg 0% 0% / 80%);
    box-shadow: 0 0 15px hsl(0deg 0% 0% / 80%);

}

.first_slider img {
    width: 452px;

}

.thumbnail_slider {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 160px;
}

.slick-list {
    padding: 0 !important;
    height: 100% !important;
}


.thumbnail_slider .slide_container {
    padding: 10px;
    opacity: 0.9;
    width: 120px;
    height: 144px;
    cursor: pointer;
}

.thumbnail_slider img {
    width: 120px;
    box-shadow: 0 0 6px rgb(0 0 0 / 90%);
    border-radius: 2px;
}

.thumbnail_slider .slick-current {
    opacity: 0.5;
}

.modal_btn_container {
    position: absolute;
    top: 11px;
    left: 10px;
}

.modal_btn_container button {
    display: inline-block;
    width: 25px;
    height: 23px;
    background: url('../../assets/images/buttons.png') 7px 6px no-repeat #000;
    cursor: pointer;
    transform: scaleX(1.2);
    border: none;
}

.arrow_container {
    background-color: black;
    text-align: center;
    width: 100%;
    /* height: 40px; */
    z-index: 22322;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
}

.arrow_container img {
    width: 25px;
    cursor: pointer;
    margin-left: 15px;
    margin-right: 15px;
}


@media screen and (max-width: 968px) {

    .thumbnail_slider {
        display: none !important;
        background: darkblue;
    }

    .first_slider .slide_container {
        background: #000;
        padding: 5px;
        margin: 0;
        margin-top: 10px;
        -webkit-box-shadow: 0 0 15px hsl(0deg 0% 0% / 80%);
        box-shadow: 0 0 15px hsl(0deg 0% 0% / 80%);


    }

    .first_slider {
        width: 100%;
        position: absolute;
        top: 92px;
        left: 0;
        transform: translateX(0);
        height: 100%;
    }

    .first_slider .slick-slide > div {
        text-align: center !important;
    }

    .first_slider .slide_container {
        background: #000;
        padding: 5px;
        -webkit-box-shadow: 0 0 15px hsl(0deg 0% 0% / 80%);
        box-shadow: 0 0 15px hsl(0deg 0% 0% / 80%);
        text-align: center;
        max-width: 430px;
    
    }

    .first_slider img {
        width: 100%;
        display: inline !important;
        max-width: 410px;
    }
}