.footer_container_bg {
    background-color: black;
    padding-top: 40px;
}

.footer_container {
    background-color: black;
    color: white;

    font-family: 'Cairo', sans-serif;

}

.footer_first_section {
    display: flex;
    max-width: 1160px;
    padding: 0 30px;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 20px;
}

.footer_first_section_left {
    width: 45%;
    padding-right: 60px;
}

.footer_first_section_right {
    width: 55%;
    padding-right: 30px;
}

.footer_second_section {
    text-align: center;
    padding-top: 10px;
    border-top: 1px solid white;
}

.footer_content_container {
    font-size: 13px;
    line-height: 22px;
}

.footer_first_section_right {
    font-size: 16px;
    line-height: 27px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    height: fit-content;
}

.footer_first_section_right span {
    padding: 0 10px;
    cursor: pointer;
    transition: background 300ms, color 300ms, border-color 300ms;
    -webkit-transition: background 300ms, color 300ms, border-color 300ms;
}

.footer_first_section_right span:hover {
    color: #5100a2;
}

.sponsers_images_container {
    margin-bottom: 15px;
}

.footer_credits {
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 2px;
}

.footer_menu_seperator {
    display: none;
}

@media screen and (max-width: 768px) {

    .footer_container_bg {
        background-color: black;
        padding-top: 40px;

    }

    .footer_first_section {
        padding-left: 30px;
        padding-right: 30px;
        display: block;
        max-width: none;
        /* margin: 0 auto; */
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 470px;
        padding-bottom: 45px;
    }

    .footer_logo_container img {
        max-width: 100%;
    }

    .footer_first_section_left {
        width: 100%;
        padding-right: 0px;
    }


    .footer_second_section {
        text-align: center;
        width: 100%;
    }

    .sponsers_images_container {
        padding-left: 30px;
        padding-right: 30px;
    }

    .sponsers_images_container img {
        max-width: 100%;
    }

    .footer_credits {

        padding-left: 30px;
        padding-right: 30px;
    }

    .footer_first_section_right {
        width: 100%;
        padding: 0px;
        height: auto;
        text-align: center;
    }

    .footer_first_section_right span:first-child {
        padding-left: 0;
    }

    .footer_menu_seperator {
        display: block;
        width: 100%;
    }


}