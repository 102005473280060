.join_discord_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url('../../assets/images/bg22.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    padding-top: 30px;

}

.jd_content_container {
    width: 60%;
    text-align: center;
    padding-bottom: 30px;
}

.jd_content_container h1 {
    font-size: 55px;
    font-weight: 800;
    line-height: 66px;
    font-family: 'Fira Sans', sans-serif;
    color: #ffffff;
    margin-bottom: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.jd_content_container p {
    font-size: 24px;
    line-height: 41px;
    font-weight: 400;
    font-family: 'Cairo', sans-serif;
    color: #ffffff;
}

.jd_content_container button {
    letter-spacing: 0.8px;
    font-weight: bold;
    font-size: 13px;
    color: #ffffff;
    background: transparent;
    border: 2px solid white;
    padding: 15px 33px;
    cursor: pointer;
    margin-top: 15px;
    font-family: 'Cairo', sans-serif;
    text-transform: uppercase;
}

.jd_img_container {
    width: 30%;
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.jd_img_container img {
    width: 100%;
    max-width: 800px;
    height: auto;
}

@media screen and (max-width: 768px) {

    .join_discord_container {
        display: block;
        background-attachment: scroll;
        padding-left: 30px;
        padding-right: 30px;
    }

    .jd_content_container {
        width: 100%;
        max-width: 470px;
        margin: 0 auto;
    }

    .jd_content_container h1 {
        font-size: 40px;
        line-height: 48px;
    }

    .jd_img_container {
        width: 100%;
        max-width: 470px;
        display: block;
        margin: 0 auto;
    }
}