.faq_container {
  flex-direction: column;
  max-width: 1160px;
  padding: 90px 50px;
  width: 100%;
  text-align: left;
  margin: 0 auto;
}

.faq_container h1 {
  margin-bottom: 45px;
  font-size: 60px;
  line-height: 72px;
  color: #5100a2;
  font-weight: 900;
  font-style: italic;
  text-align: center;
  font-family: 'Fira Sans', sans-serif;
}

.faq_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 7px 0px;
  cursor: pointer;
}

.faq_header h3 {
  font-size: 14px !important;
  line-height: 17px;
  color: #191919 !important;
  margin-bottom: 0 !important;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: 'Fira Sans', sans-serif;
}

.faq_header button {
  background-color: #f3f3f3;
  font-family: 'Cairo', sans-serif !important;
  color: #191919;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f3f3f3;
  font-size: 18px;
  width: 48px;
  height: 48px;
  cursor: pointer;
  padding: 12px;
  line-height: 20px;
  margin-right: 27px;
}

.b_active {
  background-color: white !important;
}

.card {
  border: none !important;
}

.card-header {
  border: none !important;
  background-color: white !important;
  padding: 0 !important;
}

.card-body {
  font-size: 16px;
  line-height: 27px;
  border: none !important;
  font-family: 'Cairo', sans-serif;
  color: black !important;
  margin-left: 75px;
  padding: 0 !important;
  padding-top: 10px !important;
  margin-bottom: 35px !important;
}

@media screen and (max-width: 768px) {
  .faq_container {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .faq_container h1 {
    margin-bottom: 45px;
    font-size: 40px;
    line-height: 48px;
  }
}